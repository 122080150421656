/**
 * Community edit modal
 * - Used on community management page
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { communityActionCreators } from '../../../controllers/CommunityController';

/**
 * Fabric UI
 */
import { Dialog } from '@fluentui/react/lib/Dialog';

/**
 * GovTEAMS component
 */
import CommunityForm from '../CommunityForm';

const CommunityEditModalComponent = (props) => {
    const {
        memberType,
        isEditModalOpen,
        communityId,
        communityActionCreators,
    } = props;

    const onClose = (event) => {
        communityActionCreators.endEditCommunity(communityId);
    };

    const onDismiss = (): void => {
        communityActionCreators.viewCommunityMembers(communityId);
    };

    return (
        <div className={'govTeams-edit-dialog'}>
            <Dialog
                hidden={!isEditModalOpen}
                minWidth={400}
                onDismiss={onClose}
                modalProps={{
                    isBlocking: true,
                    isDarkOverlay: true,
                    styles: {
                        main: {
                            minHeight: '90vh',
                        }
                    }
                }}
            >
                <h2>Community detail</h2>
                <CommunityForm memberType={memberType}/>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    isEditModalOpen: state.community.isEditModalOpen,
    communityId: state.community.communityId,
    communityAzureId: state.community.communityAzureId
});

const mapDispatchToProps = (dispatch: any) => ({
    communityActionCreators: bindActionCreators(communityActionCreators, dispatch)
});

export const CommunityEditModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityEditModalComponent);

export default CommunityEditModal;
