/**
 * Community edit modal
 * - Used on community management page
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/**
 * Fabric UI
 */
import { Dialog } from '@fluentui/react/lib/Dialog';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

/**
 * GovTEAMS component
 */
import CommunityFormComponent from '@govteams/library/components/Community/CommunityFormComponent/';
import * as tooltips from '../../../common/Tooltips';

import { communityActionCreators } from '../../../controllers/CommunityController';
import { authActionCreators } from '../../../controllers/Auth/';
import { isProtected } from '../../../common/Utils';
import config from '../../../config';

const CommunityEditModalComponent = (props) => {
    const {
        profile,
        isEditModalOpen,
        communityId,
        auth,
        communityActionCreators,
        initialValues
    } = props;

    const onDismiss = (): void => {
        communityActionCreators.endEditCommunity(communityId);
    };

    return (
        <Dialog
            hidden={!isEditModalOpen}
            onDismiss={onDismiss}
            minWidth={800}
            modalProps={{
                // className: 'govTeams-dialog',
                styles:{ main: {
                        maxHeight: '90vh'
                    }}
            }}
        >
            <h2>Community detail</h2>
            {!auth?.apiToken && (
                <Spinner size={SpinnerSize.small} title={'Loading...'} />
            )}
            {auth?.apiToken && (
                <CommunityFormComponent
                    isEditForm={true}

                    brandName={tooltips.BRANDNAME}
                    profile={profile}
                    initialValues={initialValues}
                    communityId={communityId}
                    isProtected={isProtected()}

                    isFormDirty={false}
                    setFormIsDirty={null}
                    findSimilarCommunities={null}
                    similarCommunities={null}
                    onSimilarCommunityClick={null}

                    requestFetchCommunity={communityActionCreators.requestFetchCommunity}
                    requestSaveCommunity={communityActionCreators.requestSaveCommunity}
                    endEditCommunity={communityActionCreators.endEditCommunity}

                    tagPickerResolveUrl={`${config.apiBaseUrl}/tags/`}
                    authToken={{
                        gtToken: {
                            accessToken: auth?.apiToken
                        },
                    }}

                    tooltipSponsoredBadge={tooltips.SPONSORED_BADGE}
                />
            )}
        </Dialog>
    );
}

const mapStateToProps = (state: any) => ({
    isEditModalOpen: state.community.isEditModalOpen,
    communityId: state.community.communityId,
    communityAzureId: state.community.communityAzureId,

    createError: state.community.error,
    initialValues: state.community.initialValues,
    communityFormState: state.form.CommunityForm,
    profile: state.profile,
    auth: state.auth,
});

const mapDispatchToProps = (dispatch: any) => ({
    communityActionCreators: bindActionCreators(communityActionCreators, dispatch),
    authActionCreators: bindActionCreators(authActionCreators, dispatch),
});

export const CommunityEditModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityEditModalComponent);

export default CommunityEditModal;
